import { useEffect } from "react";

import Loader from "./Loader";
import SiteTable from './SiteTable';

import './AuthorizedList.scss';

const AuthorizedList = ({ siteIDs, setSiteIDs, mv }: { siteIDs: string[], setSiteIDs: (siteIDs: string[]) => void, mv: (siteID: string) => void }) => {

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_DOMAIN}/authorized`)
		.then(response => response.json())
		.then((data: string[]) => {
			setSiteIDs(data);
		})
	}, []);

	return (
		<div className='authorizedlist__container'>
			<h2>Authorized list</h2>
			{
				siteIDs.length ?
					siteIDs.map(siteID => (
						<article key={siteID}>
							<SiteTable siteID={siteID} mv={mv}/>
						</article>
					))
				: <Loader />
			}
		</div>
	);

};

export default AuthorizedList;