import { useEffect, useState } from "react";

import { SiteData, PluginsList } from "../types/SiteData";

import "./SiteTable.scss";

import Loader from "./Loader";
// const Bouton = () =>{
// 	function click(){
// 		document.querySelector<SiteTable>('.table__container').classList.toggle('display-none');
// 	}
// 	return (<button onClick={click}>Afficher les informations</button>);
// }

const SiteTable = ({
  siteID,
  mv,
}: {
  siteID: string;
  mv: (siteID: string) => void;
}) => {
  function objectToArray(plugins: PluginsList[] | object) {
    if (!Array.isArray(plugins)) {
      return Object.values(plugins);
    }
    return plugins;
  }

  const [siteData, setSiteData] = useState<SiteData | null>(null);

  const updateSite = (siteID: string, option: string = "") => {
    setSiteData(null);
    const cmd = option === "force" ? "/?cmd=force" : "";
    fetch(`${process.env.REACT_APP_API_DOMAIN}/authorized/${siteID}${cmd}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data: SiteData) => {
        if (data.error) console.log("updateSiteData: ", data);
        else setSiteData(data);
      });
  };

  useEffect(() => {
    updateSite(siteID);
  }, [siteID]);

  return (
    <>
      {siteData ? (
        siteData.error ? (
          <p>Error: {siteData.error + "for " + siteID}</p>
        ) : (
          <>
            <h3 className="site-list__title">{siteData.name}</h3>
            {/* <Bouton />  */}
            <button
              onClick={() => {
                mv(siteID);
              }}
            >
              Remove from the Authorized list
            </button>
            <button
              onClick={() => {
                updateSite(siteID, "force");
              }}
            >
              Request data update
            </button>

            <div className="table__container display-none">
              <table>
                <tbody>
                  <tr className="table__row--no-border">
                    <th colSpan={2}>Data date</th>
                    <td colSpan={2}>
                      {new Date(siteData.date * 1000).toLocaleString(
                        "default",
                        {
                          day: "numeric",
                          month: "long",
                          year: "2-digit",
                          hour: "numeric",
                          minute: "numeric",
                        }
                      )}
                    </td>
                  </tr>
                  <tr className="">
                    <th colSpan={2}>IP</th>
                    <td colSpan={2}>{siteData.IP}</td>
                  </tr>
                  <tr className="">
                    <th colSpan={2}>PHP Version</th>
                    <td colSpan={2}>{siteData.phpVersion}</td>
                  </tr>
                  <tr>
                    <th colSpan={2}>WP Version</th>
                    <td colSpan={2}>{siteData.wpVersion}</td>
                  </tr>
                  <tr>
                    <th colSpan={2}>admin</th>
                    <td colSpan={2}>{siteData.admin}</td>
                  </tr>
                  <tr>
                    <th colSpan={2}>URL</th>
                    <td colSpan={2}>{siteData.wpUrl}</td>
                  </tr>
                  {objectToArray(siteData.plugins).map(
                    (plugin, index, array) => (
                      <tr
                        key={plugin.name + plugin.version}
                        className={index === 0 ? "table__row--no-border" : ""}
                      >
                        {index === 0 && (
                          <th scope="rowgroup" rowSpan={array.length}>
                            Plugins
                          </th>
                        )}
                        <th>{plugin.name}</th>
                        <td>{plugin.version}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </>
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

export default SiteTable;
