import './Loader.scss';

const Loader = () => {
	return (
		<div className='loader'>
			<span className='loader__item'>🐤</span>
			<span className='loader__item'>🐤</span>
			<span className='loader__item'>🐤</span>
			<span className='loader__item'>🐤</span>
			<span className='loader__item'>🐤</span>
		</div>
	);
}

export default Loader;